<template>
  <div class="home">
    <div class="flex flex-wrap">
      <div
        @click="$router.push({ path: '/test-methods-matrix' })"
        :style="{
          'background-image':
            'url(' + require('@/assets/boldvueUpload-min.jpg') + ')',
          cursor: 'pointer'
        }"
        style="background-position: left top;background-repeat: no-repeat;background-size: cover;"
        class="w-full front-link flex items-center justify-center"
        :class="[
          ($store.state.user.user &&
            $store.state.user.user['current-meetings']) ||
          ($store.state.user.user && $store.state.user.user['all-meetings'])
            ? 'md:w-1/3'
            : 'md:w-1/2'
        ]"
      >
        <div class="text-center p-4">
          <span
            style="text-align:center;font-size:22.5px;line-height:30.938px;font-weight:bold;color:#000000;"
          >
            TEST METHOD MATRIX: AN EXPLOSIVES TESTING RESOURCE
          </span>
        </div>
      </div>
      <div
        @click="$router.push({ path: '/standardization-and-verification' })"
        :style="{
          'background-image':
            'url(' + require('@/assets/ESDbackground-min.jpg') + ')',
          cursor: 'pointer'
        }"
        style="background-position: left top;background-repeat: no-repeat;background-size: cover;"
        class="w-full front-link flex items-center justify-center"
        :class="[
          ($store.state.user.user &&
            $store.state.user.user['current-meetings']) ||
          ($store.state.user.user && $store.state.user.user['all-meetings'])
            ? 'md:w-1/3'
            : 'md:w-1/2'
        ]"
      >
        <div class="p-4 text-center">
          <span
            style="text-align:center;font-size:22.5px;line-height:30.938px;font-weight:bold;color:#000000;"
          >
            ET USERS GROUP STANDARDIZATION AND VERIFICATION
          </span>
        </div>
      </div>
      <div
        v-if="
          ($store.state.user.user &&
            $store.state.user.user['current-meetings']) ||
            ($store.state.user.user && $store.state.user.user['all-meetings'])
        "
        @click="$router.push({ path: '/events' })"
        :style="{
          'background-image': 'url(' + require('@/assets/mtg-img.jpeg') + ')',
          cursor: 'pointer'
        }"
        style="background-position: left top;background-repeat: no-repeat;background-size: cover;"
        class="w-full md:w-1/3 front-link flex items-center justify-center cursor-pointer"
      >
        <div class="p-4 text-center">
          <span
            style="text-align:center;font-size:22.5px;line-height:30.938px;font-weight:bold;color:#000000;"
          >
            <!-- TODO: Make this a link to a page describing the next meeting -->
            UPCOMING MEETING
          </span>
        </div>
      </div>
    </div>
    <!--     <div>
      <img style="margin:0 auto;" alt="Vue logo" src="../assets/logo.png" />
    </div>
    <HelloWorld msg="Welcome to Your Vue.js App" />
    <br />
    <HelloWorld msg="Welcome to Your Vue.js App" /> -->
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";

//import { functions, auth } from "../firebaseConfig";

//var user = firebase.auth().currentUser;

export default {
  name: "Home",
  components: {
    //HelloWorld
  },
  mounted() {
    //this.$store.dispatch('user/getAllProducts');
    /*     var fullName = functions.httpsCallable("fullName");
    //For the fullName we have defined that fullName takes some data as a parameter
    fullName({
      firstName: "bilbo",
      lastName: "baggins"
    })
      .then(result => {
        console.log(result);
      })
      .catch(error => {
        console.log(error);
      }); */
    /*     console.log(auth);
    let email = "trevordowdle@gmail.com";
    let password = "joejoe"; */
    /*     auth.signInWithEmailAndPassword(email, password)
    .then((user)=>{
      debugger;
      //this.setLoggedInUserAsAdmin()
      this.createUser();
    })
    .catch(function(error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      debugger;
      // ...
    }); */
  },
  methods: {
    setLoggedInUserAsAdmin() {
      //var setAdmin = functions.httpsCallable("setLoggedInUserAsAdmin");
      //setAdmin();
    },
    createUser() {
      /*       var createUser = functions.httpsCallable("createUser");
      //For the fullName we have defined that fullName takes some data as a parameter
      createUser({
        firstName: "trevor",
        lastName: "dowdle",
        password: "joejoe",
        email: "tdowdle@gmail.com",
        role: "user"
      })
        .then(result => {
          console.log(result);
          debugger;
        })
        .catch(error => {
          console.log(error.code);
          console.log(error.message);
          debugger;
        }); */
    }
  }
};
</script>

<style lang="scss">
.home {
  background-image: linear-gradient(180deg, #ffffff 0%, #f88b1c 100%);
}
/* Work on setting conditional height */
.front-link {
  height: 600px;
}
</style>
