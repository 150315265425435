import admin from "../../api/admin";

// initial state
const state = () => ({
  users: [],
  roles: {
    admin: "Admin",
    "all-meetings": "All Meetings",
    "current-meetings": "Current Meetings",
    "friction-test": "Friction Test Standardization",
    "impact-test": "Impact Test Standardization",
    "esd-test": "ESD Test Standardization",
    "thermal-test-dsc": "Thermal Test Standardization DSC",
    "thermal-test-sbat": "Thermal Test Standardization SBAT",
    "koenen-test": "Koenen Test Standardization",
    "detonator-test": "Detonator Test Standardization"
  }
});

// getters
const getters = {
  isUserSelected: state => {
    return state.users.find(user => user.metadata.checked) ? true : false;
  },
  roleList: state => {
    return Object.keys(state.roles);
  },
  userIndexMap: state => {
    return state.users.reduce((map, user, i) => {
      map[user.uid] = i;
      return map;
    }, {});
  }
};

// actions
const actions = {
  getAllUsers({ commit }) {
    admin.getUsers().then(users => {
      commit("setUsers", users);
    });
  },
  updateChecked({ commit }, info) {
    commit("updateChecked", info);
  },
  addRole({ state, commit }, role) {
    let selected = state.users
      .map((user, i) => {
        user.index = i;
        return user;
      })
      .filter(user => user.metadata.checked);
    if (!selected.length) {
      return false;
    }
    let info = {
      selected: selected,
      role: role
    };
    admin.addRole(info).then(resp => {
      if (resp && resp.length && resp[0] === "success") {
        commit("addRole", info);
      }
    });
  },
  removeRole({ state, commit }, role) {
    let selected = state.users
      .map((user, i) => {
        user.index = i;
        return user;
      })
      .filter(user => user.metadata.checked);
    if (!selected.length) {
      return false;
    }
    let info = {
      selected: selected,
      role: role
    };
    admin.removeRole(info).then(resp => {
      if (resp && resp.length && resp[0] === "success") {
        commit("removeRole", info);
      }
    });
  },
  removeUser({ state, commit }, role) {
    let selected = state.users
      .map((user, i) => {
        user.index = i;
        return user;
      })
      .filter(user => user.metadata.checked);
    if (!selected.length) {
      return false;
    }
    let info = {
      selected: selected
    };
    admin.removeUser(info).then(resp => {
      if (resp && resp.length && resp[0] === "success") {
        commit("removeUser", info);
      }
    });
  },
  createUser({ state, commit }, info) {
    return admin.createUser(info).then(resp => {
      if (resp && resp.result === "success") {
        alert("user created");
        commit("addUser", resp.user);
        return resp.user;
      }

      return false;
    });
  }
};

// mutations
const mutations = {
  setUsers(state, users) {
    state.users = users;
  },
  updateChecked(state, info) {
    let metadata = JSON.parse(JSON.stringify(state.users[info.index].metadata));
    metadata.checked = info.checked;
    state.users[info.index].metadata = metadata;
  },
  addRole(state, info) {
    info.selected.forEach(user => {
      let clone = JSON.parse(
        JSON.stringify(state.users[user.index].customClaims)
      );
      clone[info.role] = true;
      state.users[user.index].customClaims = clone;
    });
  },
  removeRole(state, info) {
    info.selected.forEach(user => {
      let clone = JSON.parse(
        JSON.stringify(state.users[user.index].customClaims)
      );
      delete clone[info.role];
      state.users[user.index].customClaims = clone;
    });
  },
  removeUser(state, info) {
    info.selected.reverse().forEach(user => {
      state.users.splice(user.index, 1);
    });
  },
  addUser(state, user) {
    user.index = state.users.length;
    user.customClaims = { user: true };
    state.users.push(user);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
