<template>
  <nav class="header fixed w-full">
    <div v-show="scrollTop" class="secondary-header">
      <div class="grid grid-cols-2 gap-4">
        <div class="text-left">
          <div class="contact-info">
            <label style="margin-right:3px;">Contact us:</label>
            <span class="contact-info-phone-number">801.567.0456</span>
          </div>
        </div>
        <div class="text-right pt-2">
          <div v-if="$store.state.user.user">
            <span>Logged in as {{ $store.state.user.user.email }}</span>
            <button
              class="ml-2 bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded"
              @click="logout"
              v-if="$store.state.user.user"
            >
              Logout
            </button>
          </div>
          <div v-if="!$store.state.user.user">
            <button
              class="ml-2 bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded"
              @click="login"
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      ref="headerEl"
      class="flex items-center justify-between flex-wrap p-6 bg-white"
      style="border-bottom:1px solid #e0e0e0;height:127px;"
    >
      <div class="flex items-center flex-shrink-0 mr-6" style="margin-top:7px;">
        <img
          class="w-48 logo"
          alt="sms logo"
          src="@/assets/etuglogo-web2-min.png"
          style="max-height:71px;width:279px;"
        />
      </div>
      <div class="block lg:hidden">
        <button
          @click="toggleMenu"
          class="flex items-center px-3 py-2 border rounded border-orange-400 hover:text-orange-600 hover:border-orange-700"
        >
          <svg
            class="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div
        :class="{ hidden: !showMenu }"
        class="w-full block flex-grow lg:flex lg:items-center lg:w-auto z-20 mt-4 border-b-2 border-grey-500 lg:border-b-0 lg:mt-12"
        style="background-color:white;"
      >
        <div class="text-sm lg:flex-grow lg:-mt-12">
          <router-link
            v-for="header in headerData"
            :key="header.name"
            class="pt-3 base-link block mt-3 lg:inline-block lg:mt-0 mr-4 lg:max-w-lg"
            :class="{
              'hover:text-yellow-600': !header.group,
              group: header.group,
              home: header.name === 'Home'
            }"
            :to="header.route"
          >
            <span v-if="!header.group" class="base-text font-bold">{{
              header.name
            }}</span>
            <button
              v-if="header.group"
              class="w-full flex items-center outline-none focus:outline-none hover:text-yellow-600"
            >
              <span class="base-text font-bold">{{ header.name }}</span>
            </button>
            <ul
              v-if="header.group"
              class="bg-gray-200 border rounded-sm transform scale-0 group-hover:scale-100 absolute 
          transition duration-150 ease-in-out origin-top min-w-32"
              style="max-width:16rem;z-index:10;"
            >
              <li
                v-for="subGroup in header.subGroups"
                :key="subGroup.name"
                class="rounded-sm relative hover:bg-gray-100"
                :class="{ 'hover:text-yellow-600': !subGroup.group }"
              >
                <router-link
                  v-if="!subGroup.group"
                  :to="(subGroup.skip ? '' : header.route) + subGroup.route"
                >
                  <div style="width:100%;" class="base-text px-3 py-1">
                    {{ subGroup.name }}
                  </div>
                </router-link>
                <button
                  :data-link="
                    (subGroup.skip ? '' : header.route) + subGroup.route
                  "
                  @click="openLink"
                  v-if="subGroup.group"
                  class="w-full text-left flex items-center outline-none focus:outline-none hover:text-yellow-600"
                >
                  <router-link
                    :to="(subGroup.skip ? '' : header.route) + subGroup.route"
                  >
                    <div
                      style="width:100%;"
                      class="pr-1 flex-1 base-text px-3 py-1"
                    >
                      {{ subGroup.name }}
                    </div>
                  </router-link>
                  <span class="mr-auto pointer-events-none">
                    <svg
                      class="fill-current h-4 w-4
                              transition duration-150 ease-in-out"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                      />
                    </svg>
                  </span>
                </button>
                <ul
                  v-if="subGroup.group"
                  :class="{ 'flow-left': header.flowLeft }"
                  class="bg-gray-200 border rounded-sm absolute top-0
                              transition duration-150 ease-in-out origin-top-left min-w-32"
                >
                  <li
                    v-for="subSubGroup in subGroup.subGroups"
                    :key="subSubGroup.name"
                    class="hover:bg-gray-100 hover:text-yellow-600"
                  >
                    <router-link :to="header.route + subSubGroup.route">
                      <div style="width:100%;" class="px-3 py-1 base-text">
                        <span>{{ subSubGroup.name }}</span>
                      </div>
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </router-link>
          <!--           <router-link
            v-if="true"
            class="base-link-cart block mt-4 lg:-mt-2 lg:inline-block hover:text-yellow-600 mr-4 align-middle"
            to="/cart"
            ><svg
              style="width:16px;"
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="shopping-cart"
              class="svg-inline--fa fa-shopping-cart fa-w-18"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
            >
              <path
                fill="currentColor"
                d="M528.12 301.319l47.273-208C578.806 78.301 567.391 64 551.99 64H159.208l-9.166-44.81C147.758 8.021 137.93 0 126.529 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24h69.883l70.248 343.435C147.325 417.1 136 435.222 136 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-15.674-6.447-29.835-16.824-40h209.647C430.447 426.165 424 440.326 424 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-22.172-12.888-41.332-31.579-50.405l5.517-24.276c3.413-15.018-8.002-29.319-23.403-29.319H218.117l-6.545-32h293.145c11.206 0 20.92-7.754 23.403-18.681z"
              ></path></svg
          ></router-link> -->
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { headerData } from "./headerData.ts";

export default {
  name: "Header",
  props: {
    msg: String
  },
  data: function() {
    return {
      headerData,
      showMenu: false,
      scrollTop: true
    };
  },
  mounted() {
    window.onscroll = () => {
      if (document.documentElement.scrollTop < 40) {
        this.scrollTop = true;
      } else {
        this.scrollTop = false;
      }
    };
  },
  methods: {
    openLink() {
      if (Event.target.nodeName === "BUTTON") {
        if (this.$router.currentRoute.path !== Event.target.dataset.link) {
          this.$router.push({ path: Event.target.dataset.link });
        }
        Event.preventDefault();
        Event.stopPropagation();
      }
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    logout() {
      this.$store.dispatch("user/logout").then(() => {
        //reroute to main page
        //this.$router.push("/");
      });
    },
    login() {
      this.$router.push({ path: "/login" });
    }
  }
};
</script>

<style lang="scss" src="./Header.scss"></style>
