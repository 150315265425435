import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/library",
    name: "Library",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "library" */ "../views/Library.vue")
  },
  {
    path: "/charter",
    name: "Charter",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "charter" */ "../views/Charter.vue")
  },
  {
    path: "/test-methods-matrix",
    name: "Test Method Matrix",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "testmethodmatrix" */ "../views/TestMethodMatrix.vue"
      )
  },
  {
    path: "/standardization-and-verification",
    name: "Standardization And Verification",
    component: () =>
      import(
        /* webpackChunkName: "standardizationverification" */ "../views/StandardizationVerification.vue"
      )
  },
  {
    path: "/in-process-classification",
    name: "In Process Classification",
    component: () =>
      import(
        /* webpackChunkName: "inprocessclassification" */ "../views/InProcessClassification.vue"
      )
  },
  {
    path: "/un-manual-classification-system",
    name: "UN Manual Classification System",
    component: () =>
      import(
        /* webpackChunkName: "unmanualclassificationsystem" */ "../views/UnManualClassificationSystem.vue"
      )
  },
  {
    path: "/mbom-impact-test",
    name: "Mbom Impact Test",
    component: () =>
      import(
        /* webpackChunkName: "mbomimpacttest" */ "../views/IPSeries/MbomImpactTest.vue"
      )
  },
  {
    path: "/abl-friction-test",
    name: "ABL Friction Test",
    component: () =>
      import(
        /* webpackChunkName: "ablfrictiontest" */ "../views/IPSeries/AblFrictionTest.vue"
      )
  },
  {
    path: "/abl-esd-test",
    name: "ABL ESD Test",
    component: () =>
      import(
        /* webpackChunkName: "ablesdtest" */ "../views/IPSeries/AblESDTest.vue"
      )
  },
  {
    path: "/ip-thermal-test",
    name: "IP Thermal Test",
    component: () =>
      import(
        /* webpackChunkName: "thermaltest" */ "../views/IPSeries/ThermalTest.vue"
      )
  },
  {
    path: "/zero-gap-test",
    name: "Zero Gap Test",
    component: () =>
      import(
        /* webpackChunkName: "zerogaptest" */ "../views/IPSeries/ZeroGapTest.vue"
      )
  },
  {
    path: "/ip-koenen-test-2",
    name: "Koenen Test",
    component: () =>
      import(
        /* webpackChunkName: "koenentest" */ "../views/IPSeries/KoenenTest.vue"
      )
  },
  {
    path: "/ip-timepressure-test",
    name: "TimePressure Test",
    component: () =>
      import(
        /* webpackChunkName: "timepressure" */ "../views/IPSeries/TimePressureTest.vue"
      )
  },
  {
    path: "/IP-internal-ignition-test",
    name: "Internal Ignition Test",
    component: () =>
      import(
        /* webpackChunkName: "internalignition" */ "../views/IPSeries/InternalIgnitionTest.vue"
      )
  },
  {
    path: "/ip-small-scale-burning-test",
    name: "IPSmallBurningTest",
    component: () =>
      import(
        /* webpackChunkName: "smallburningtest" */ "../views/IPSeries/IpSmallBurningTest.vue"
      )
  },
  {
    path: "/ip-cap-sensitivity-test",
    name: "IpCapSensitivityTest",
    component: () =>
      import(
        /* webpackChunkName: "ipcapsensitivitytest" */ "../views/IPSeries/IpCapSensitivityTest.vue"
      )
  },
  {
    path: "/nol-card-gap",
    name: "NOL Card Gap",
    component: () =>
      import(
        /* webpackChunkName: "nocardgap" */ "../views/IPSeries/NolCardGapTest.vue"
      )
  },
  {
    path: "/process-simulation-tests",
    name: "Process Simulation Tests",
    component: () =>
      import(
        /* webpackChunkName: "processsimulationtests" */ "../views/IPSeries/ProcessSimulationTests.vue"
      )
  },
  {
    path: "/critical-diameter-test",
    name: "Critical Diameter Test",
    component: () =>
      import(
        /* webpackChunkName: "criticaldiametertest" */ "../views/IPSeries/CriticalDiameterTest.vue"
      )
  },
  {
    path: "/critical-height-test",
    name: "Critical height Test",
    component: () =>
      import(
        /* webpackChunkName: "criticalheighttest" */ "../views/IPSeries/CriticalHeightTest.vue"
      )
  },
  {
    path: "/ip-koenen-test-4",
    name: "IP Koenen Test",
    component: () =>
      import(
        /* webpackChunkName: "ipkoenentest" */ "../views/IPSeries/IPKoenenTest.vue"
      )
  },
  {
    path: "/ip-drop-test",
    name: "IP Drop Test",
    component: () =>
      import(
        /* webpackChunkName: "ipdroptest" */ "../views/IPSeries/IPDropTest.vue"
      )
  },
  {
    path: "/thermal-characterization-tests",
    name: "Article Thermal Characterization Tests",
    component: () =>
      import(
        /* webpackChunkName: "articlethermalcharacterization" */ "../views/IPSeries/ArticleThermalStability.vue"
      )
  },
  {
    path: "/propagation-tests",
    name: "Propagation Tests",
    component: () =>
      import(
        /* webpackChunkName: "propagationtests" */ "../views/IPSeries/PropagationTests.vue"
      )
  },
  {
    path: "/ip-external-fire-test",
    name: "IP External Fire Test",
    component: () =>
      import(
        /* webpackChunkName: "externalfiretest" */ "../views/IPSeries/ExternalFireTest.vue"
      )
  },
  {
    path: "/shield-testing",
    name: "Shield Testing",
    component: () =>
      import(
        /* webpackChunkName: "shieldtesting" */ "../views/IPSeries/ShieldTesting.vue"
      )
  },
  {
    path: "/tnt-equivalency",
    name: "TNT Equivalency",
    component: () =>
      import(
        /* webpackChunkName: "tntequivalency" */ "../views/IPSeries/TNTEquivalency.vue"
      )
  },
  {
    path: "/un-gap-test",
    name: "UN Gap Test",
    component: () =>
      import(
        /* webpackChunkName: "tntequivalency" */ "../views/UN/UnGapTest.vue"
      )
  },
  {
    path: "/koenen-test",
    name: "Koenen Test",
    component: () =>
      import(/* webpackChunkName: "koenent" */ "../views/UN/KoenenTest.vue")
  },
  {
    path: "/timepressure-test",
    name: "Time Pressure Test",
    component: () =>
      import(
        /* webpackChunkName: "timeptest" */ "../views/UN/TimePressureTest.vue"
      )
  },
  {
    path: "/internal-ignition-test",
    name: "Internal Ignition Test",
    component: () =>
      import(
        /* webpackChunkName: "internalignitiontest" */ "../views/UN/InternalIgnitionTest.vue"
      )
  },
  {
    path: "/un-impact-boe",
    name: "Un Impact Test",
    component: () =>
      import(
        /* webpackChunkName: "unimpacttest" */ "../views/UN/UnImpactTest.vue"
      )
  },
  {
    path: "/un-impact-mbom",
    name: "MBOM Impact Test",
    component: () =>
      import(
        /* webpackChunkName: "mbomitest" */ "../views/UN/MbomImpactTest.vue"
      )
  },
  {
    path: "/un-friction-bam",
    name: "UN Friction Test",
    component: () =>
      import(
        /* webpackChunkName: "unfrictiontest" */ "../views/UN/FrictionTest.vue"
      )
  },
  {
    path: "/un-friction",
    name: "Un Friction",
    component: () =>
      import(
        /* webpackChunkName: "unfriction" */ "../views/UN/AblFrictionTest.vue"
      )
  },
  {
    path: "/un-substance-thermal-stability",
    name: "Un Thermal Stability",
    component: () =>
      import(
        /* webpackChunkName: "unthermalstability" */ "../views/UN/ThermalStability.vue"
      )
  },
  {
    path: "/un-sbat-thermal-stability-test-at-75-c",
    name: "Un Sbat Thermal Stability",
    component: () =>
      import(
        /* webpackChunkName: "unsbatthermalstability" */ "../views/UN/SBATThermalStabilityDetailed.vue"
      )
  },
  {
    path: "/small-scale-burning-test",
    name: "Small Scale Burning Test",
    component: () =>
      import(
        /* webpackChunkName: "unsmallburn" */ "../views/UN/SmallBurningTest.vue"
      )
  },
  {
    path: "/un-article-thermal-stability",
    name: "Article Thermal Stability",
    component: () =>
      import(
        /* webpackChunkName: "articlethermalstability" */ "../views/UN/ArticleThermalStability.vue"
      )
  },
  {
    path: "/12-meter-drop",
    name: "12 Meter Drop",
    component: () =>
      import(
        /* webpackChunkName: "12meterdrop" */ "../views/UN/12MeterDrop.vue"
      )
  },
  {
    path: "/cap-sensitivity-test",
    name: "Cap Sensitivity Test",
    component: () =>
      import(
        /* webpackChunkName: "capsensitivitytest" */ "../views/UN/CapSensitivityTest.vue"
      )
  },
  {
    path: "/usa-ddt-test",
    name: "USA DDT Test",
    component: () =>
      import(/* webpackChunkName: "usaddttest" */ "../views/UN/USADDTTest.vue")
  },
  {
    path: "/single-package-test",
    name: "Single Package Test",
    component: () =>
      import(
        /* webpackChunkName: "singlepackagetest" */ "../views/UN/SinglePackageTest.vue"
      )
  },
  {
    path: "/stack-test",
    name: "Stack Test",
    component: () =>
      import(/* webpackChunkName: "stacktest" */ "../views/UN/StackTest.vue")
  },
  {
    path: "/external-fire-test",
    name: "External Fire Test",
    component: () =>
      import(
        /* webpackChunkName: "externalfiretest" */ "../views/UN/ExternalFireTest.vue"
      )
  },
  {
    path: "/unconfined-package-test",
    name: "Unconfined Package Test",
    component: () =>
      import(
        /* webpackChunkName: "unconfinedpackagetest" */ "../views/UN/UnconfinedPackageTest.vue"
      )
  },
  {
    path: "/EIS-gap-test",
    name: "EIS Gap Test",
    component: () =>
      import(/* webpackChunkName: "eisgaptest" */ "../views/UN/EISGapTest.vue")
  },
  {
    path: "/friability-test",
    name: "Friability Test",
    component: () =>
      import(
        /* webpackChunkName: "eisgaptest" */ "../views/UN/FriabilityTest.vue"
      )
  },
  {
    path: "/eis-bullet-impact-test",
    name: "EIS Bullet Impact Test",
    component: () =>
      import(
        /* webpackChunkName: "eisbullet" */ "../views/UN/BulletImpactTest.vue"
      )
  },
  {
    path: "/eis-external-fire-test",
    name: "EIS External Fire Test",
    component: () =>
      import(/* webpackChunkName: "eisbullet" */ "../views/UN/ExternalFire.vue")
  },
  {
    path: "/eis-slow-cook-off-test",
    name: "EIS Slow Cook Off Test",
    component: () =>
      import(
        /* webpackChunkName: "eisslowcook" */ "../views/UN/SlowCookoff.vue"
      )
  },
  {
    path: "/1-6-article-external-fire-test",
    name: "Article External Fire Test",
    component: () =>
      import(
        /* webpackChunkName: "articleexfire" */ "../views/UN/ArticleExFire.vue"
      )
  },
  {
    path: "/1-6-article-slow-cook-off-test",
    name: "Article Slow Cook Off Test",
    component: () =>
      import(
        /* webpackChunkName: "articleslowcookofftest" */ "../views/UN/16SlowCookoffTest.vue"
      )
  },
  {
    path: "/1-6-article-bullet-impact-test",
    name: "Article Bullet Test",
    component: () =>
      import(
        /* webpackChunkName: "articlebullettest" */ "../views/UN/16BulletImpactTest.vue"
      )
  },
  {
    path: "/1-6-article-stack-test",
    name: "Article Stack Test",
    component: () =>
      import(
        /* webpackChunkName: "articlestacktest" */ "../views/UN/16StackTest.vue"
      )
  },
  {
    path: "/ane-gap-test",
    name: "Ane Gap Test",
    component: () =>
      import(/* webpackChunkName: "anegaptest" */ "../views/UN/AneGapTest.vue")
  },
  {
    path: "/vented-pipe-test",
    name: "Vented Pipe Test",
    component: () =>
      import(
        /* webpackChunkName: "ventedpipetest" */ "../views/UN/VentedPipeTest.vue"
      )
  },
  {
    path: "/slsgt",
    name: "Super Large Scale Gap Test",
    component: () =>
      import(/* webpackChunkName: "slsgt" */ "../views/UN/SLSGT.vue")
  },
  {
    path: "/standardization-and-verification/friction-standardization",
    name: "Friction Standardization",
    component: () =>
      import(
        /* webpackChunkName: "frictionstandardization" */ "../views/StandardVerification/FrictionStandardization.vue"
      )
  },
  {
    path: "/standardization-and-verification/bam-friction-standardization",
    name: "BAM Friction Standardization",
    component: () =>
      import(
        /* webpackChunkName: "frictionstandardization" */ "../views/StandardVerification/BAMFrictionStandardization.vue"
      )
  },
  {
    path: "/standardization-and-verification/impact-standardization",
    name: "Impact Standardization",
    component: () =>
      import(
        /* webpackChunkName: "impactstandardization" */ "../views/StandardVerification/ImpactStandardization.vue"
      )
  },
  {
    path: "/standardization-and-verification/esd-standardization",
    name: "ESD Standardization",
    component: () =>
      import(
        /* webpackChunkName: "esdstandardization" */ "../views/StandardVerification/ESDStandardization.vue"
      )
  },
  {
    path: "/standardization-and-verification/thermal-standardization",
    name: "Thermal Standardization",
    component: () =>
      import(
        /* webpackChunkName: "thermalstandardization" */ "../views/StandardVerification/ThermalStandardization.vue"
      )
  },
  {
    path: "/standardization-and-verification/koenen-standardization",
    name: "Koenen Standardization",
    component: () =>
      import(
        /* webpackChunkName: "koenenstandardization" */ "../views/StandardVerification/KoenenStandardization.vue"
      )
  },
  {
    path: "/standardization-and-verification/detonator-verification",
    name: "Detonator Verification",
    component: () =>
      import(
        /* webpackChunkName: "detonatorverification" */ "../views/StandardVerification/DetonatorVerification.vue"
      )
  },

  {
    path: "/standardization-and-verification/round-robin-participation",
    name: "Round Robin Participation",
    component: () =>
      import(
        /* webpackChunkName: "roundrobinparticipation" */ "../views/Forms/RoundRobinParticipation.vue"
      )
  },

  {
    path: "/contact-us",
    name: "Contact Us",
    component: () =>
      import(/* webpackChunkName: "contactus" */ "../views/Forms/ContactUs.vue")
  },

  {
    path: "/meeting-registration",
    name: "Meeting Registration",
    component: () =>
      import(
        /* webpackChunkName: "meetingregistration" */ "../views/Forms/MeetingRegistration.vue"
      )
  },

  {
    path: "/equipment-standards-procedures",
    name: "ETUG Standards",
    component: () =>
      import(
        /* webpackChunkName: "etugstandards" */ "../views/Library/EtugStandards.vue"
      )
  },

  {
    path:
      "/Library/equipment-standards-procedures" /* this link needs to remain here as its referenced in NFPA 495*/,
    name: "ETUG Standards",
    component: () =>
      import(
        /* webpackChunkName: "etugstandards" */ "../views/InProcessClassification.vue"
      )
  },

  {
    path: "/reaction-detection-discussion",
    name: "Reaction Detection Discussion",
    component: () =>
      import(
        /* webpackChunkName: "reactiondetection" */ "../views/Library/ReactionDetection.vue"
      )
  },

  {
    path: "/technical-papers",
    name: "Technical Papers",
    component: () =>
      import(
        /* webpackChunkName: "technicalpapers" */ "../views/Library/TechnicalPapers.vue"
      )
  },

  {
    path: "/meeting-proceedings",
    name: "Meeting Proceedings",
    component: () =>
      import(
        /* webpackChunkName: "meetingproceedings" */ "../views/Library/GroupMeetings.vue"
      )
  },

  {
    path: "/october-2023-meeting",
    name: "October 2023 Meeting",
    component: () =>
      import(/* webpackChunkName: "2023meeting" */ "../views/Meetings/2023.vue")
  },

  {
    path: "/october-2022-meeting",
    name: "October 2022 Meeting",
    component: () =>
      import(/* webpackChunkName: "2022meeting" */ "../views/Meetings/2022.vue")
  },

  {
    path: "/october-2021-meeting",
    name: "October 2021 Meeting",
    component: () =>
      import(/* webpackChunkName: "2021meeting" */ "../views/Meetings/2021.vue")
  },

  {
    path: "/october-2020-meeting",
    name: "October 2020 Meeting",
    component: () =>
      import(/* webpackChunkName: "2020meeting" */ "../views/Meetings/2020.vue")
  },

  {
    path: "/october-2019-meeting",
    name: "October 2019 Meeting",
    component: () =>
      import(/* webpackChunkName: "2019meeting" */ "../views/Meetings/2019.vue")
  },

  {
    path: "/october-2018-meeting",
    name: "October 2018 Meeting",
    component: () =>
      import(/* webpackChunkName: "2018meeting" */ "../views/Meetings/2018.vue")
  },

  {
    path: "/october-2017-meeting",
    name: "October 2017 Meeting",
    component: () =>
      import(/* webpackChunkName: "2017meeting" */ "../views/Meetings/2017.vue")
  },

  {
    path: "/october-2016-meeting",
    name: "October 2016 Meeting",
    component: () =>
      import(/* webpackChunkName: "2016meeting" */ "../views/Meetings/2016.vue")
  },

  {
    path: "/october-2015-meeting",
    name: "October 2015 Meeting",
    component: () =>
      import(/* webpackChunkName: "2015meeting" */ "../views/Meetings/2015.vue")
  },

  {
    path: "/events",
    name: "Events",
    component: () =>
      import(/* webpackChunkName: "events" */ "../views/Events.vue")
  },

  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/user-admin",
    name: "Info",
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "info" */ "../views/Info.vue")
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
