var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"flex flex-wrap"},[_c('div',{staticClass:"w-full front-link flex items-center justify-center",class:[
        (_vm.$store.state.user.user &&
          _vm.$store.state.user.user['current-meetings']) ||
        (_vm.$store.state.user.user && _vm.$store.state.user.user['all-meetings'])
          ? 'md:w-1/3'
          : 'md:w-1/2'
      ],staticStyle:{"background-position":"left top","background-repeat":"no-repeat","background-size":"cover"},style:({
        'background-image':
          'url(' + require('@/assets/boldvueUpload-min.jpg') + ')',
        cursor: 'pointer'
      }),on:{"click":function($event){return _vm.$router.push({ path: '/test-methods-matrix' })}}},[_vm._m(0)]),_c('div',{staticClass:"w-full front-link flex items-center justify-center",class:[
        (_vm.$store.state.user.user &&
          _vm.$store.state.user.user['current-meetings']) ||
        (_vm.$store.state.user.user && _vm.$store.state.user.user['all-meetings'])
          ? 'md:w-1/3'
          : 'md:w-1/2'
      ],staticStyle:{"background-position":"left top","background-repeat":"no-repeat","background-size":"cover"},style:({
        'background-image':
          'url(' + require('@/assets/ESDbackground-min.jpg') + ')',
        cursor: 'pointer'
      }),on:{"click":function($event){return _vm.$router.push({ path: '/standardization-and-verification' })}}},[_vm._m(1)]),(
        (_vm.$store.state.user.user &&
          _vm.$store.state.user.user['current-meetings']) ||
          (_vm.$store.state.user.user && _vm.$store.state.user.user['all-meetings'])
      )?_c('div',{staticClass:"w-full md:w-1/3 front-link flex items-center justify-center cursor-pointer",staticStyle:{"background-position":"left top","background-repeat":"no-repeat","background-size":"cover"},style:({
        'background-image': 'url(' + require('@/assets/mtg-img.jpeg') + ')',
        cursor: 'pointer'
      }),on:{"click":function($event){return _vm.$router.push({ path: '/events' })}}},[_vm._m(2)]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center p-4"},[_c('span',{staticStyle:{"text-align":"center","font-size":"22.5px","line-height":"30.938px","font-weight":"bold","color":"#000000"}},[_vm._v(" TEST METHOD MATRIX: AN EXPLOSIVES TESTING RESOURCE ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-4 text-center"},[_c('span',{staticStyle:{"text-align":"center","font-size":"22.5px","line-height":"30.938px","font-weight":"bold","color":"#000000"}},[_vm._v(" ET USERS GROUP STANDARDIZATION AND VERIFICATION ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-4 text-center"},[_c('span',{staticStyle:{"text-align":"center","font-size":"22.5px","line-height":"30.938px","font-weight":"bold","color":"#000000"}},[_vm._v(" UPCOMING MEETING ")])])
}]

export { render, staticRenderFns }