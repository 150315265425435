export const headerData = [
 {
    name:'HOME',
    route:'/'
 },
 {
   name:'CHARTER',
   route:'/charter'
},
{
   name:'TMM',
   route:'/test-methods-matrix'
},
{
   name:'STANDARDIZATION',
   route:'/standardization-and-verification'
},
{
   name:'LIBRARY',
   route:'/library'
}
];