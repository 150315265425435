<template>
  <footer class="footer">
    <footer class="footer-bottom">
      <div class="fusion-row p-4">
        <div class="fusion-copyright-content">
          <div class="fusion-social-links-footer">
            <div class="fusion-social-networks">
              <div class="fusion-social-networks-wrapper">
                <div class="grid md:grid-cols-1 lg:grid-cols-2 gap-4">
                  <div>
                    <div class="fusion-copyright-notice">
                      <div>
                        © Explosives Testing Users Group | All Rights Reserved
                      </div>
                      <div>
                        Sponsored by
                        <a href="https://smsenergetics.com" target="_blank"
                          >Safety Management Services, Inc.</a
                        >
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- fusion-fusion-copyright-content -->
      </div>
      <!-- fusion-row -->
    </footer>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: {
    msg: String
  },
  data: function() {
    return {};
  }
};
</script>

<style lang="scss" src="./Footer.scss"></style>
