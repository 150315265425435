<template>
  <div id="app" :class="[userClasses]">
    <Header />
    <div class="content" style="padding-top:11rem;">
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header/Header.vue";
import Footer from "@/components/Footer/Footer.vue";

export default {
  components: {
    Header,
    Footer
  },
  beforeCreate() {
    this.$store.dispatch("user/syncUser");
  },
  data: function() {
    return {
      roleFilter: {
        admin: true,
        "current-meetings": true,
        "friction-test": true,
        "impact-test": true,
        "esd-test": true,
        "thermal-test-dsc": true,
        "thermal-test-sbat": true,
        "koenen-test": true,
        "all-meetings": true,
        "detonator-test": true
      }
    };
  },
  computed: {
    userClasses: function() {
      console.log(this.$store.state.user.user);
      return this.$store.state.user.user
        ? Object.keys(this.$store.state.user.user)
            .reduce((arr, key) => {
              if (this.roleFilter[key]) {
                arr.push(`role-${key}`);
              }
              return arr;
            }, [])
            .join(" ")
        : "";
    }
  }
};
</script>

<style lang="scss">
#app {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  font-style: normal;

  .link {
    color: rgb(239, 154, 61);
  }

  .p-light {
    color: #7e8890;
    line-height: 32px;
  }

  iframe {
    @apply m-auto;
  }

  .test-table {
    color: #7e8890;
    min-width: 640px;
    @apply table-auto;

    tbody {
      line-height: 34px;
      tr:nth-child(even) {
        background: #f6f6f6;
      }
      tr td {
        @apply border px-4 py-2 font-medium;
      }
    }
  }

  .latest-meeting,
  .prior-meeting {
    display: none;
  }

  &.role-all-meetings .latest-meeting,
  &.role-all-meetings .prior-meeting {
    display: block;
  }

  &.role-current-meetings .latest-meeting {
    display: block;
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
