import { /*functions,*/ auth } from "../firebaseConfig";

/**
 * Mocking client-server processing
 */
const _products = [
  { id: 1, title: "iPad 4 Mini", price: 500.01, inventory: 2 },
  { id: 2, title: "H&M T-Shirt White", price: 10.99, inventory: 10 },
  { id: 3, title: "Charli XCX - Sucker CD", price: 19.99, inventory: 5 }
];

export default {
  passwordReset(email) {
    return auth.sendPasswordResetEmail(email);
  },
  login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  },
  logout() {
    return auth.signOut();
  },
  syncUser(syncFunction) {
    auth.onAuthStateChanged(syncFunction);
    return auth.onAuthStateChanged;
    /*         
            auth().onAuthStateChanged(function(user) {
                if (user) {
                    // User is signed in.
                } else {
                    // No user is signed in.
                }
            }); 
        */
  },
  getProducts(cb) {
    setTimeout(() => cb(_products), 100);
  },

  buyProducts(products, cb, errorCb) {
    setTimeout(() => {
      // simulate random checkout failure.
      Math.random() > 0.5 || navigator.webdriver ? cb() : errorCb();
    }, 100);
  }
};

/* 


  */
