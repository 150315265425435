import * as firebase from "firebase/app";
// Required for side-effects
require("firebase/functions");
require("firebase/auth");
require("firebase/analytics");

const firebaseConfig = {
  apiKey: "AIzaSyAfZfYWUzLCZphOLz-krWiuM9xFxMlIbAU",
  authDomain: "etusersgroup.firebaseapp.com",
  databaseURL: "https://etusersgroup.firebaseio.com",
  projectId: "etusersgroup",
  storageBucket: "etusersgroup.appspot.com",
  messagingSenderId: "399171513626",
  appId: "1:399171513626:web:3befab45e5f01d488350a3",
  measurementId: "G-QFP9LYPQVY"
};

const app = firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics(app);

var functions, auth;

if (process.env.NODE_ENV === "development") {
  //firebase.functions().useFunctionsEmulator("http://localhost:5001");
}

functions = firebase.functions();
auth = firebase.auth();

export { functions, auth };
