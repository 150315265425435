import user from "../../api/user";

// initial state
const state = () => ({
  all: [],
  user: undefined,
  authState: null,
  roleFilter: {
    admin: true,
    "current-meetings": true,
    "friction-test": true,
    "impact-test": true,
    "esd-test": true,
    "thermal-test-dsc": true,
    "thermal-test-sbat": true,
    "koenen-test": true,
    "detonator-test": true,
    "all-meetings": true
  }
});

// getters
const getters = {
  userData: state => {
    return state.user
      ? (({ admin, user, user_id, email }) => ({
          user: user || admin,
          user_id,
          email
        }))(state.user)
      : {};
  },
  authObserve: state => state.authState,
  userClasses: state => {
    return state.user
      ? Object.keys(state.user).reduce((obj, key) => {
          if (state.roleFilter[key]) {
            obj[`role-${key}`] = true;
          }
          return obj;
        }, {})
      : {};
  }
};

// actions
const actions = {
  passwordReset(empty, email) {
    return user.passwordReset(email);
  },
  login(empty, data) {
    return user.login(data.email, data.password);
  },
  logout() {
    return user.logout();
  },
  syncUser({ commit }) {
    let sync = function(user) {
      if (user) {
        user
          .getIdTokenResult()
          .then(idTokenResult => {
            // Confirm the user is an Admin.
            commit("setUser", idTokenResult.claims);
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        commit("setUser", user);
      }
    };
    user.syncUser(sync);
  }
};

// mutations
const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setAuthState(state, authState) {
    state.authState = authState;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
