import { functions } from "../firebaseConfig";


export default {
    getUsers() {
        return functions.httpsCallable("listUsers")().then((res)=>{
            return res.data;
        })
        .catch(err=>{
            console.log(err);
        });
    },
    addRole(info) {
        return functions.httpsCallable("addRole")(info).then((res)=>{
            return res.data;
        })
        .catch(err=>{
            console.log(err);
        });
    },
    removeRole(info){
        return functions.httpsCallable("removeRole")(info).then((res)=>{
            return res.data;
        })
        .catch(err=>{
            console.log(err);
        });
    },
    removeUser(info){
        return functions.httpsCallable("removeUser")(info).then((res)=>{
            return res.data;
        })
        .catch(err=>{
            console.log(err);
        });
    },
    createUser(info){
        return functions.httpsCallable("createUser")({
            firstName: info["grid-first-name"],
            lastName: info["grid-last-name"],
            password: info["grid-password"],
            email: info["grid-email"],
            role: "user"
          }).then((res)=>{
            return res.data;
        })
        .catch(err=>{
            alert(err.message);
            console.log(err);
            return false;
        });
    }   
};
