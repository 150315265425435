import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styles/tailwind.scss";
import { /*functions,*/ auth } from "./firebaseConfig";

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  console.log(store.state.user.user);
  if (to.name === "Login" && store.state.user.user === undefined) {
    let unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        next({ name: "Home" });
      } else {
        next();
      }
      unsubscribe();
    });
    return false;
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.user.user === undefined) {
      let unsubscribe = auth.onAuthStateChanged(user => {
        if(!user){
          next({ name: "Login", query: { redirect: to.path } });
          return false;
        }
        user.getIdTokenResult()
        .then(idTokenResult => {
          let isUser = (({ admin, user, }) => (user || admin))(idTokenResult.claims);
          if (!isUser) {
            next({ name: "Login", query: { redirect: to.path } });
          } else {
            next();
          }
        });
        unsubscribe();
      });
    } else {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      if (!store.getters["user/userData"].user) {
        next({ name: "Login", query: { redirect: to.path } });
      } else {
        next(); // go to wherever I'm going
      }
    }
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
